<template>
    <div>
        <h1>Get to know me as a person</h1>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>