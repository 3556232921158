<template>
    <div>
        <h1>How to contact me</h1>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>